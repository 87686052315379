import { useRef } from 'react';

export const useMemoizedSelector = <TState, TInput, TResult>(
  inputSelector: (state: TState) => TInput,
  resultFunc: (input: TInput) => TResult
) => {
  const lastInputRef = useRef<TInput>();
  const lastResultRef = useRef<TResult>();

  return (state: TState): TResult => {
    const input = inputSelector(state);

    if (input !== lastInputRef.current) {
      lastInputRef.current = input;
      lastResultRef.current = resultFunc(input);
    }

    return lastResultRef.current!;
  };
};
