import React, { useCallback } from 'react';
import styled from 'styled-components';
import { DisplayTable } from '../../Reusables/DisplayTable';
import { BLUE } from '../../Constants/Styles';
import { setCustomerThunk } from '../../Global/customerActions';
import { iAwnCustomer, iCustomer } from '../../Global/customerReducer';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { iState } from '../../configureStore';
import { useMemoizedSelector } from '../../utils/Hooks/useMemoizedSelector';

const StyledContainer = styled.div`
  width: 1150px;
  margin: 30px;
  max-height: 100vh;
`;

const StyledTitle = styled.h3`
  margin-left: 14px;
  color: ${BLUE.primary};
`;

const headers = ['Name', 'ID', 'UUID'];

type Props = {
  onCloseModal: () => void;
};

export const CustomerSelection: React.FC<Props> = ({ onCloseModal }) => {
  const dispatch = useAppDispatch();

  const selectCustomers = useMemoizedSelector<
    iState,
    Map<string, iAwnCustomer>,
    iAwnCustomer[]
  >(
    (state) => state.customer.customers,
    (customers) => Array.from(customers.values() || [])
  );

  const customers = useSelector(selectCustomers);

  const filters: Array<any> = [
    { text: '3', value: '3' },
    { text: '10', value: '10' },
    { text: '20', value: '20' },
  ];

  const handleSelectCustomer = useCallback(
    (uuid: string) => {
      dispatch(setCustomerThunk(uuid, true));
      const allCustomers = JSON.parse(
        localStorage.getItem('customers') || '[]'
      );
      const customer = allCustomers.find(
        (c: iAwnCustomer) => c.riskID === uuid
      );
      if (customer) {
        customer.deploymentID = `${customer.name}0`;
        const customerDetail: iCustomer = {
          name: customer.name,
          deploymentID: `${customer.id}0`,
          id: uuid,
          label: customer.riskID,
        };
        localStorage.setItem('currentCustomer', JSON.stringify(customerDetail));
      }
    },
    [dispatch]
  );

  return (
    <StyledContainer>
      <StyledTitle>Customer Select</StyledTitle>
      <DisplayTable
        focus={true}
        filterOptions={filters}
        rowClick={(row: Array<string>) => {
          handleSelectCustomer(row[2]);
          onCloseModal();
        }}
        headers={headers}
        rows={customers.map((customer: iAwnCustomer) => [
          customer.name,
          customer.id,
          customer.riskID,
        ])}
      />
    </StyledContainer>
  );
};
